import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppLayout from "./Components/AppLayout";

ReactDOM.render(
  // <React.StrictMode>
    <AppLayout />,
  // </React.StrictMode>,
  document.getElementById('root')
);